export const headingstyles = {
  background: "white",
  color: "rgb(255,86,0)",
  fontFamily:"Arial",
  // minWidth:"1024px",
  //   overflowX: "auto",
  //   overflowY: "auto",
    borderRadius: '10px'
}

export const innerheadingStyles = {
    background: "white",
    display: "flex",
    paddingLeft: "0",
    color: "rgb(255,86,0)",
    spacing:3,
    pl:"0",
    fontFamily:"Arial",
    zIndex: 1300,
    marginBottom: '20px',
    width: '100%', // Set width to 100% to occupy full width of the screen
    maxWidth: '1024px', // Set a maximum width for the content
    overflowX: 'auto', // Enable horizontal scrolling
    overflowY: 'auto', // Enable vertical scrolling
    borderRadius: '10px',
    }

export const noclassGridStyles = {
  backgroundColor: '#D3D3D3',
  width: '180px',
  height: '120px',
  borderRadius: '12px',
  mt: '10px',
  mr: '0px',
  pl: '0',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  alignContent: 'space-around',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  marginBottom: '10%',
};


// export const dynamicButtonStyles = {
//   backgroundColor: isActive ? 'rgb(255, 86, 0)' : 'white',
//   color: isActive ? 'black' : 'black', // Change text color as needed
//   borderColor: 'black',
//   padding: '4px',
//   width: '153px',
//   fontFamily: 'Arial',
    
    
//   };

export const weekdaysStyles = {
  alignItems: 'baseline',
  pt: '20px', // Reduce top padding
  color: 'black !important',
  fontFamily: 'Arial',
  marginTop: '20px',
  maxHeight:{xs:"300px" ,lg:'100%'}
};

export const gridStyles = {
  backgroundColor: 'rgb(193,215,239)',
  width: '100%',
  height: '100%',
  borderRadius: '12px',
  // mt: '10px',
  // mr: '10px', // Add margin to create a gap between grids
  // pl: '10px', // Add padding for content inside the grid
  display: 'flex',
  flexDirection: 'column',
  padding:'10px',
  flexWrap: 'wrap',
  alignContent: 'space-around',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  marginBottom: '10%',
  overflow: 'hidden', // Hide overflowed content
  textOverflow: 'ellipsis', // Show ellipsis (...) for overflowed text
  wordWrap: 'break-word', // Allow long words to wrap onto the next line
  whiteSpace: 'pre-wrap', // Preserve white spaces and wrap lines
};



  export const noclassmessagecarStyles={
    backgroundColor: 'rgb(193,215,239)',
    width:'250px',
    height:'120px',
    borderRadius: '12px',
    mt:"10%",
    mr:"0px",
    ml:"30%",
    display:'flex',
    flexDirection:"column",
    flexWrap:"wrap",
    alignContent:"space-around",
    justifyContent:"space-evenly",
    alignItems:"flex-start",
    marginBottom:"10%" 
    
  }

export const monthStyle={
  // fontFamily:"Arial Hebrew",
  fontWeight: 'bold',
  
}