import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useState, useEffect } from "react"
import {
  gridStyles,
  headingstyles,
  innerheadingStyles,
  monthStyle,
  noclassGridStyles,
  noclassmessagecarStyles,
  weekdaysStyles,
} from "./calenderstyles"
import axios from "axios"
import { useAppSelector } from "../../app/hooks"

interface CalenderProps {
  class: boolean
}

const Calender = (props: CalenderProps) => {
  const [activeButtons, setActiveButtons] = useState("")
  const [activeStudent, setActiveStudent] = useState("")
  const [activeIndex, setActiveIndex] = useState(0)
  const [gradeButtons, setGradeButtons] = useState([])
  const [calendarData, setCalendarData] = useState([])
  const user = useAppSelector((state) => state.user)

  useEffect(() => {
    if (user.userDetails?.children_details) {
      let childrenDetails = user.userDetails?.children_details
      console.log(childrenDetails)
      let result = []

      if (props.class) {
        // Condition where we transform the array as is
        result = childrenDetails?.map((child) => ({
          studentName: child.Child_Name,
          grade: child.Grade,
          classes: child.Subjects,
        }))
      } else {
        // Condition where we remove duplicates in grade
        const seenGrades = new Set()

        result = childrenDetails
          ?.filter((child) => {
            if (seenGrades.has(child.Grade)) {
              return false
            } else {
              seenGrades.add(child.Grade)
              return true
            }
          })
          ?.map((child) => ({
            studentName: child.Child_Name,
            grade: child.Grade,
            classes: child.Subjects,
          }))
      }

      if (props.class) {
        setActiveStudent(result?.[0]?.studentName)
      }
      setActiveButtons(result?.[0]?.grade)
      getCalendar(result?.[0]?.grade, result?.[0]?.classes[0] || null)
      setGradeButtons(result)
    }
  }, [user.userDetails])

  const getCalendar = async (grade: string, subject?: string) => {
    const url = props.class
      ? `https://atlas.smsplus.lk/api/v1/class/schedules?grade=${grade}&subject=${subject}`
      : `https://atlas.smsplus.lk/api/v1/class/schedules?grade=${grade}`

    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer 1eAMTBHRwNcvA0JqBsup9xkwsywcHmnp3eoh9Wkj9a2ea2cc`,
        },
      })
      .then((response) => {
        let data = [
          { day: "Monday", schedule: [] },
          { day: "Tuesday", schedule: [] },
          { day: "Wednesday", schedule: [] },
          { day: "Thursday", schedule: [] },
          { day: "Friday", schedule: [] },
          { day: "Saturday", schedule: [] },
          { day: "Sunday", schedule: [] },
        ]

        if (response.data.length > 0) {
          response.data.map((item: any) => {
            switch (item.on.toLowerCase()) {
              case "monday":
                data[0].schedule.push(item)
                break
              case "tuesday":
                data[1].schedule.push(item)
                break
              case "wednesday":
                data[2].schedule.push(item)
                break
              case "thursday":
                data[3].schedule.push(item)
                break
              case "friday":
                data[4].schedule.push(item)
                break
              case "saturday":
                data[5].schedule.push(item)
                break
              case "sunday":
                data[6].schedule.push(item)
                break
              default:
                break
            }
          })

          data.map((item: any) => {
            item.schedule.sort((a: any, b: any) => {
              if (a.start_at < b.start_at) return -1
              if (a.start_at > b.start_at) return 1
              return 0
            })
          })
        }

        setCalendarData(data)
      }).catch((error:any)=>{
        let data = [
          { day: "Monday", schedule: [] },
          { day: "Tuesday", schedule: [] },
          { day: "Wednesday", schedule: [] },
          { day: "Thursday", schedule: [] },
          { day: "Friday", schedule: [] },
          { day: "Saturday", schedule: [] },
          { day: "Sunday", schedule: [] },
        ]
        setCalendarData(data)
      })
  }

  const getBackgroundColor = (subject: string) => {
    let background = "#3BA6F4"
    switch (subject) {
      case "Art Classes":
        background = "#ED1B6D"
        break
      case "Math Classes":
        background = "#016CA0"
        break
      case "English Classes":
        background = "#01939D"
        break
      case "Sinhala Classes":
        background = "#9F0177"
        break
      default:
        break
    }
    return background
  }

  const getBackgroundColorRound = (subject: string) => {
    let background = "#2875ac"
    switch (subject) {
      case "Art Classes":
        background = "#9b1448"
        break
      case "Math Classes":
        background = "#003955"
        break
      case "English Classes":
        background = "#00545a"
        break
      case "Sinhala Classes":
        background = "#52003d"
        break
      default:
        break
    }
    return background
  }

  const dynamicButtonStyles = {
    backgroundColor: "white",
    color: "black",
    borderColor: "black",
    border: "1px solid transparent", // Ensure there's an initial transparent border
    padding: "4px",
    width: "100%",
    fontFamily: "Arial",
    transition: "border-color 0.3s ease", // Add transition for smooth effect
    "&:hover": {
      color: "black",
      borderColor: "black", // Change border color on hover
    },
  }

  const handleClick = (value: any, index: number) => {
    console.log(value)
    if (props.class) {
      setActiveStudent(value?.studentName)
    }
    setActiveIndex(index)
    setActiveButtons(value?.grade)
    getCalendar(value?.grade, value?.classes[0] || null)
  }

  const activeButtonState = (value: any) => {
    if (props.class) {
      if (activeButtons == value.grade && activeStudent == value.studentName) {
        return true
      } else {
        return false
      }
    } else {
      if (activeButtons == value.grade) {
        return true
      } else {
        return false
      }
    }
  }

  console.log(calendarData)

  return (
    <Box
      sx={{
        ...headingstyles,
        backgroundColor: "#F7F7F7",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#EFEFEF",
          height: "auto",
          display: "flex",
          flexDirection: "row",
          gap: 2,
          padding: "10px",
          borderRadius: "10px",
          overflow: "auto",
        }}
      >
        {gradeButtons?.map((data: any, index) => (
          <Button
            key={index}
            sx={{
              padding: "10px",
              width: "200px",
              minWidth: "200px",
              backgroundColor: activeButtonState(data)
                ? "rgb(255,86,0)"
                : "#e2e2e2",
              color: activeButtonState(data) ? "white" : "black",
              fontWeight: 600,
              fontSize: "16px",
              "&:hover": {
                color: activeButtonState(data) ? "#fff" : "black",
                borderColor: "black",
                backgroundColor: activeButtonState(data)
                  ? "rgb(255,86,0)"
                  : "#d3d3d3",
              },
            }}
            onClick={() => handleClick(data, index)}
          >
            <Box>
              {props.class && <Typography>{data?.studentName}</Typography>}
              <Typography>{data?.grade}</Typography>
            </Box>
          </Button>
        ))}
      </Box>
      
      <Box
        sx={{
          minWidth: "100%",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            rowGap: 1,
            columnGap: 2,
            width: "100%",
            minHeight: "600px",
            overflowX: "auto",
          }}
        >
          {calendarData.map((day: any, index: number) => (
            <Box key={index}>
              <Box
                sx={{ width: "225px", marginTop: "10px", marginBottom: "10px" }}
              >
                <Typography>{day.day}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {[...Array(4)].map((_, gridIndex) => (
                  <Box key={gridIndex}>
                    {gridIndex < day.schedule.length ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          maxWidth: "205px",
                          minWidth: "205px",
                          minHeight: "100px",
                          maxHeight: "100px",
                          padding: "10px",
                          borderRadius: "10px",
                          color: "#000",
                        }}
                        style={{
                          backgroundColor: getBackgroundColor(
                            day.schedule[gridIndex].curriculum.subject.name,
                          ),
                        }}
                      >
                        <Box sx={{ fontWeight: "bold", display: "flex" }}>
                          <div
                            style={{
                              backgroundColor: getBackgroundColorRound(
                                day.schedule[gridIndex].curriculum.subject.name,
                              ),
                              borderRadius: 10,
                              width: "15px",
                              height: "15px",
                              marginRight: "10px",
                            }}
                          ></div>
                          {day.schedule[gridIndex].curriculum.subject.name}
                        </Box>
                        <Box sx={{ marginLeft: "22px" }}>
                          {day.schedule[gridIndex].teacher}
                        </Box>
                        <Box sx={{ fontWeight: "bold", marginLeft: "22px" }}>
                          {day.schedule[gridIndex].start_at.slice(0, 5)} -{" "}
                          {day.schedule[gridIndex].finish_at.slice(0, 5)}
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "225px",
                          height: "120px",
                          backgroundColor: "#d3d3d3",
                          borderRadius: "10px",
                        }}
                      ></Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      {calendarData?.every(
        (item) => Array.isArray(item.schedule) && item.schedule.length === 0,
      ) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            marginTop: "10px",
          }}
        >
          <Typography
            sx={{ color: "#FF5600", fontSize: "18px", fontWeight: 500 }}
          >
            *No classes are currently scheduled. Please check back later.
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default Calender
