import React, { useEffect } from "react"
import { Provider } from "react-redux"
import App from "../App"
import LandingPage from "../pages/landing-page"
import RegisterPage from "../pages/register"
import { persistor, store } from "../app/store"
import { PersistGate } from "redux-persist/integration/react"
import ProfileDetailsPage from "../pages/profile-details"
import HomePage from "../pages/home-page"
import AdminLogin from "../pages/admin/adminLogin"
import Calender from "../components/calender/calender"
import Editproduct from "../components/admin/productmanagement/edit-product"
import { Route, Navigate, useNavigate } from "react-router-dom"
import { isUserAuthenticated } from "../services/auth"
import { useAppSelector } from "../app/hooks"
import Forgotpassword from "../components/admin/login/Forgotpassword/forgotpassword"
import Otpview from "../components/admin/login/Forgotpassword/otpview"
import Resetpassword from "../components/admin/login/Forgotpassword/resetpassword"
import MainComponent from "../components/main"
import ProductManagement from "../pages/admin/productManagement"
import CampaignManagement from "../pages/admin/campaignManagement"
import Setting from "../pages/admin/setting"
import DeleteAccountPage from "../pages/deleteAccount-page"
import AppPage from "../pages/app-page"

const PrivateRoute = ({ element: Element, auth, ...rest }) => {
  const isAuthenticated = isUserAuthenticated()

  if (auth && !isAuthenticated) {
    return <Navigate to="/admin/login" />
  }

  return <Route {...rest} element={<Element />} />
}

const HandleadminAuth = () => {
  const admin = useAppSelector((state) => state.admin)
  // console.log(admin,"admin")
  const navigate = useNavigate()
  useEffect(() => {
    if (admin.isLoggedIn) {
      navigate("/admin/producttable")
    } else {
      navigate("/admin/login")
    }
  }, [admin.isLoggedIn])
}

export const routes = {
  path: "/",
  element: (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  ),
  children: [
    {
      path: "/",
      element: <LandingPage />,
      auth: false,
    },
    {
      path: "/register",
      element: <RegisterPage />,
      auth: false,
    },
    {
      path: "/delete-account",
      element: <DeleteAccountPage />,
      auth: false,
    },
    {
      path: "/quizMaster",
      element: <AppPage />,
      auth: false,
    },
    {
      path: "/home",
      element: <HomePage />,
      auth: false,
    },
    {
      path: "/profile-details",
      element: <ProfileDetailsPage />,
      auth: false,
    },

    {
      path: "/calender",
      element: <Calender class={false} />,
      auth: false,
    },
    {
      path: "/admin/login",
      element: <AdminLogin/>,
      auth: false,
    },
    {
      path: "/admin/forgotpassword",
      element: <Forgotpassword />,
      auth: false,
    },
    {
      path: "/admin/otpview",
      element: <Otpview />,
      auth: false,
    },
    {
      path: "/admin/resendpassword",
      element: <Resetpassword />,
      auth: false,
    },
    // {
    //   path: "/admin/navbar",
    //   element: <Adminnavbar/>,
    //   auth:true
    // },
    {
      path: "/admin/producttable",
      element: (
        <MainComponent>
     <ProductManagement />
        </MainComponent>
      ),
      auth: true,
    },

    {
      path: "/admin/campaignmanagement",
      element: (
        <MainComponent>
        <CampaignManagement/>
        </MainComponent>
      ),
      auth: true,
    },
    // {
    //   path: "/admin/productmanagement",
    //   element: <PrivateRoute element={<Producttable />} auth={true} />,
    //   auth:true
    // },
    {
      path: "/admin/profile",
      element: (
        <MainComponent>
        <Setting />
        </MainComponent>
      ),
      auth: true,
    },
  ],
}
