import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material"
import { ContainerStyles, FooterTextStyles, LinkStyles } from "./styles"
import { useNavigate } from "react-router-dom"
import Logo from "../../assets/img/footer-bg.png"
import { BiSearch } from "react-icons/bi"
import { SocialMediaIcon } from "../socialMediaIcon"
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import { useAppSelector } from "../../app/hooks"

const Footer = () => {
  const navigate = useNavigate()

  const user = useAppSelector((state) => state.user)

  return (
    <Container sx={ContainerStyles}>
      <Grid
        container
        sx={{
          gap: "7px",
          alignItems: "flex-start",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Grid item>
          <Box
            component="img"
            sx={{
              height: "auto",
              maxHeight: { xs: 129, md: 129 },
              marginRight: { sm: "-50px" },
              maxWidth: "100%",
            }}
            alt="ATLAS World Logo"
            src={Logo}
            onClick={() => navigate("/")}
          />
        </Grid>
        <Grid
          item
          sx={{
            flex: "auto",
            display: "flex",
            width: { xs: "100%", lg: "auto" },
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Grid container>
            <Grid item>
              <Grid
                container
                sx={{
                  gap: { xs: "20px", md: "60px" },
                  alignItems: { xs: "flex-start", md: "center" },
                  flexDirection: { xs: "column", md: "row" },
                  marginTop: "39px",
                  marginBottom: "37px",
                }}
              >
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginTop: "20px",
                      gap: 1,
                    }}
                  >
                    <Typography sx={LinkStyles}>
                      {"Terms & Conditions  "}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        "& a": {
                          fontWeight: 400,
                          color: "#FFFFFF",
                          textDecoration: "none",
                          fontSize: "14px",
                          fontFamily: "Arial",
                        },
                      }}
                    >
                      <Link
                        style={{
                          borderRight: "solid 2px #fff",
                          paddingRight: 15,
                        }}
                        href="/Atlas-World-T&C-English.pdf"
                        target="_blank"
                        underline="hover"
                      >
                        English
                      </Link>
                      <Link
                        href="/Atlas-World-T&C-Sinhala.pdf"
                        target="_blank"
                        underline="hover"
                      >
                        Sinhala
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginTop: "20px",
                      marginBottom:"20px",
                      gap: 1,
                    }}
                  >
                    <Link
                      href="Data Privacy Notice - Atlas World English v2.1.pdf"
                      target="_blank"
                      underline="hover"
                    >
                      <Typography sx={LinkStyles}>
                        {"Data Protection"}
                      </Typography>
                    </Link>
                    {/* <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        "& a": {
                          fontWeight: 400,
                          color: "#FFFFFF",
                          textDecoration: "none",
                          fontSize: "14px",
                          fontFamily: "Arial"
                        },
                      }}
                    >
                      <Link style={{ borderRight: "solid 2px #fff", paddingRight: 15 }}
                        href="Data Privacy Notice - Atlas World English v2.1.pdf"
                        target="_blank"
                        underline="hover"
                      >
                        English
                      </Link>
                      <Link
                        href="Data Privacy Notice - Atlas World Sinhala v2.0.pdf"
                        target="_blank"
                        underline="hover"
                      >
                        Sinhala
                      </Link>
                    </Box> */}
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginTop: "20px",
                      marginBottom:"20px",
                      gap: 1,
                    }}
                  >
                    <Link
                      href="Cookies Policy Notice - Atlas World English v1.0.pdf"
                      target="_blank"
                      underline="hover"
                    >
                      <Typography sx={LinkStyles}>
                        {"Cookies Policy"}
                      </Typography>
                    </Link>
                    </Box>
                    </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Grid
                    container
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <Grid item>
                      <Typography sx={FooterTextStyles}>
                        XW99+Q6V, Old Kandy Rd, Peliyagoda 11300
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                      gap: { xs: "10px", sm: "33px" },
                    }}
                  >
                    <Grid item>
                      <Typography sx={FooterTextStyles}>
                        Phone: <a href="tel:+94112912879">+94 772 111 333</a>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={FooterTextStyles}>
                        Fax: <a href="fax:+94112912879">+94 112 912 879</a> |{" "}
                        <a href="fax:+94112912879">+94 112 912 879</a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              width: { xs: "100%", lg: "50%" },
              justifyContent: { xs: "flex-start", lg: "flex-end" },
            }}
          >
            <Grid item>
              <Grid
                container
                sx={{
                  alignItems: "center",
                  marginTop: "39px",
                  marginBottom: "37px",
                  justifyContent: { xs: "flex-start", lg: "flex-end" },
                }}
              >
                <Grid item>
                  <FormControl
                    sx={{ m: 0, width: "281px", height: "46px" }}
                    variant="outlined"
                  >
                    {/* <OutlinedInput
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "70px",
                        paddingRight: "16px",
                        height: "46px",
                        "& fieldset": { border: "none" },
                        "& input": { paddingLeft: "37px" },
                      }}
                      id="search"
                      type="text"
                      placeholder="Search"
                      endAdornment={
                        <InputAdornment
                          sx={{ "& .MuiButtonBase-root": { p: 0 } }}
                          position="end"
                        >
                          <IconButton
                            sx={{
                              color: "#ffffff",
                            }}
                            aria-label="search"
                            // onClick={}
                            // onMouseDown={}
                            edge="end"
                          >
                            <Box
                              sx={{
                                backgroundColor: "#FF5600",
                                display: "flex",
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                "& svg": {
                                  width: "21.95px",
                                  height: "21.95px",
                                },
                              }}
                            >
                              <BiSearch />
                            </Box>
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    /> */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  justifyContent: { xs: "flex-start", lg: "flex-end" },
                  gap: "6.71px",
                }}
              >
                <Grid item>
                  <SocialMediaIcon linkUrl="https://www.facebook.com/atlas.lk">
                    <FaFacebookF />
                  </SocialMediaIcon>
                </Grid>
                <Grid item>
                  <SocialMediaIcon linkUrl="https://www.instagram.com/atlas.srilanka/">
                    <FaInstagram />
                  </SocialMediaIcon>
                </Grid>
                <Grid item>
                  <SocialMediaIcon linkUrl="https://www.youtube.com/@atlassrilanka956">
                    <FaYoutube />
                  </SocialMediaIcon>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  justifyContent: { xs: "flex-start", lg: "flex-end" },
                  marginTop: "18px",
                }}
              >
                {!user.isLoggedIn && (
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        color: "#FFCCB2",
                        textAlign: { xs: "left", lg: "right" },
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        navigate("/delete-account")
                      }}
                    >
                      Delete Account
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Footer
