import { Box, Grid, Tab, Tabs, Typography } from "@mui/material"
import React from "react"
import Calender from "../calender/calender"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const TabCalenderView = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (newValue: number) => {
    setValue(newValue)
  }
  return (
    <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",width:"100%"}}>
      <Typography
        sx={{
          color: "#fff",
          fontSize: "40px",
          fontWeight: 700,
          paddingBottom: "40px",
        }}
      >
        WEEKLY TIMETABLE
      </Typography>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#F7F7F7",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <Box
            sx={{
              display: "flex",
              backgroundColor: value == 0 ? "#D9D9D9" : "#F7F7F7",
              padding: "20px",
              borderTopLeftRadius: "10px",
              cursor:"pointer",
              "&:hover": {
                backgroundColor:"#e2e2e2"
              }
            }}
            onClick={()=>{handleChange(0)}}
          >
            <Typography
              sx={{
                fontSize: "18px", // Set font size
                fontWeight: 700,
                color: value == 0 ? "#000000" : "#909090",
                textAlign: "center",
              }}
            >
              All Classes
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              backgroundColor: value == 1 ? "#D9D9D9" : "#F7F7F7",
              padding: "20px",
              borderTopRightRadius: "10px",
              cursor:"pointer",
              "&:hover": {
                backgroundColor:"#e2e2e2"
              }
            }}
            onClick={()=>{handleChange(1)}}
          >
            <Typography
              sx={{
                fontSize: "18px", // Set font size
                fontWeight: 700,
                color: value == 1 ? "#000000" : "#909090",
                textAlign: "center",
              }}
            >
              My Classes
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          pb: "10px",
          backgroundColor: "#F7F7F7",
          borderRadius: "10px",
          borderTopLeftRadius: "0px",
        }}
      >
        <CustomTabPanel value={value} index={0}>
          <Calender class={false}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Calender class={true}/>
        </CustomTabPanel>
      </Box>
    </Box>
  )
}

export default TabCalenderView
